var name = 'room';
page.names.push(name);

page[name] = class PageClassName extends page.GenPage{





    // init

    init(ajaxBool = false){

        super.init();



        /* Image & Content Slider */

        $('#room__imageContent_image').slick({
            dots: false,
            arrows: false,
            infinite: true,
            speed: 500,
            fade: false,
            cssEase: 'linear',
            draggable: false,
            swipe: false,
            asNavFor: '#room__imageContent_content',
            responsive: [
                {
                    breakpoint: 900,
                    settings: {
                        draggable: true,
                        swipe: true,
                    }
                }
            ]
        });

        $('#room__imageContent_content').slick({
            dots: true,
            arrows: false,
            infinite: true,
            speed: 500,
            fade: true,
            cssEase: 'linear',
            draggable: false,
            swipe: false,
            adaptiveHeight: false,
            asNavFor: '#room__imageContent_image',
            responsive: [
                {
                    breakpoint: 900,
                    settings: {
                        draggable: true,
                        swipe: true,
                    }
                },
                {
                    breakpoint: 680,
                    settings: {
                        draggable: true,
                        swipe: true,
                        adaptiveHeight: true,
                        fade: false,
                    }
                }
            ]
        });



        /* Inclusive SameHeight */

        this.inclusiveSameHeight = new v.SameHeight({
            selector: '.room__item_inclusive .room__inclusive-item'
        });
        this.inclusiveSameHeight = this.inclusiveSameHeight.init();



    }





    // destroy

    destroy(ajaxBool = false){

        super.destroy(ajaxBool);

        if(this.inclusiveSameHeight){
            this.inclusiveSameHeight.changeProp({
                resize: false
            });
        }

    }





};

page[name] = new page[name];
page[name] = page[name].create();